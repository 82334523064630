<template>
    <div
        class="main-frame"
    >
        <img
            :src="require('@/assets/img/'+img)"
            v-touch:swipe.left="swipeLeft"
            v-touch:swipe.right="swipeRight"
        >
    </div>
<!--    <div class="console">
        {{log}}
    </div>-->
</template>

<script setup>
import {inject, ref} from "vue";

/** @type Env */
const env = inject('env')

const imgs = [
    '01.png',
    '02.png',
    '03.png',
    '04.png',
    '05.png',
    '06.png',
    '07.png',
    '08.png',
    '09.png',
    '10.png',
    '11.png',
]

const log = ref('console')

const img = ref(imgs[0])

function swipeLeft(){
    let currentIndex = imgs.indexOf(img.value)
    img.value = currentIndex+1<imgs.length?imgs[currentIndex+1]:imgs[0]
    log.value = 'Left'
}

function swipeRight(){
    let currentIndex = imgs.indexOf(img.value)
    img.value = currentIndex===0?imgs[imgs.length-1]:imgs[currentIndex-1]
    log.value = 'Right'
}

</script>

<style lang="scss">


html,
body{
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

#app {
    background: white;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    display: flex;
    align-items: center;
    justify-content: center;

    .main-frame{
        width: 100vw;
        height: 100vh;
        margin: 0;

        img{
            width: 100%;
            height: auto;
            touch-action: pan-x;
        }

    }
}

</style>
